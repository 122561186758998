<template>
  <div>
    <AuthWrap :title="$t('authPage.restPwd')" :text="$t('personPage.phone')">
      <form class="form" @submit.prevent="resetPwd">
        <PrimaryInput
          style="margin-bottom: 1.875rem"
          :placeholderText="$t('register.login')"
          :success="phone.length > 12"
          :errText="
            errors && errors !== 'session_exists'
              ? $t(`resetPwd.${errors}`)
              : errors == 'valid_format'
              ? $t(`resetPwd.${valid_format}`)
              : errorsPhone.phone
              ? $t(`register.errorPhone`)
              : ''
          "
        >
          <input
            placeholder="+998 "
            type="text"
            id="number"
            maxlength="13"
            class="text-input"
            v-model="phone"
            @focus="focusPhone"
          />
        </PrimaryInput>
        <!-- <button class="gradient-btn next-btn">
          {{ $t("buttons.send") }}
        </button> -->
        <PrimaryButton style="width: 100%" :title="$t('buttons.send')" />
      </form>
    </AuthWrap>

    <ModalContainer v-if="showModal">
      <div class="modal">
        <div class="title">
          {{ $t("NewRegister.LogoutOtherGadget") }}
        </div>
        <router-link
          class="gradient-btn go-to"
          :to="$i18nRoute({ name: 'Home' })"
        >
          {{ $t("registrationExem.home") }}
        </router-link>
      </div>
    </ModalContainer>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
// import { sendCode } from "@/network/v1/index";
import { useRouter } from "vue-router";
import { useLanguage } from "@/utils/language";
import { useStore } from "vuex";
import ModalContainer from "@/components/ModalContainer.vue";
import AuthWrap from "@/components/AuthWrap.vue";
import PrimaryInput from "@/components/PrimaryInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";

export default {
  components: { ModalContainer, AuthWrap, PrimaryInput, PrimaryButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { i18nRoute } = useLanguage();

    const showModal = ref(false);

    const phones = reactive({
      phone: "",
      phoneMask: "+998",
    });
    const errorsPhone = reactive({
      phone: false,
    });
    const errors = ref("");
    const valid_format = ref("");
    const focusPhone = () => {
      errors.value = "";
      valid_format.value = "";

      errorsPhone.phone = false;
      phones.phone = phones.phoneMask;
    };
    const resetPwd = () => {
      if (phones.phone.length > 4) {
        errorsPhone.phone = false;
      } else {
        errorsPhone.phone = true;
      }
      if (errorsPhone.phone == false) {
        store.dispatch("resetPassword/sendCode", {
          cb: (data) => {
            errors.value = data.error;
            valid_format.value = data.valid_format;
            if (errors.value == "session_exists") {
              showModal.value = true;
            }
            if (data.success || data.error == "code_already_sent") {
              router.push(
                i18nRoute({
                  name: "ConfirmCode",
                })
              );
            }
          },
          phone: { phone: phones.phone },
          err: () => {},
        });
      }
    };
    return {
      showModal,
      ...toRefs(phones),
      errorsPhone,
      resetPwd,
      focusPhone,
      errors,
      valid_format,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid {
  font-size: 16px;

  max-width: var(--container-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(11, 1fr var(--gap)) 1fr;
  &__img {
    position: relative;
    grid-column: 1/10;
    margin-top: 5em;
    img {
      background: transparent;
      position: absolute;
      right: 0;
      top: 0;
      top: 50%;
      width: 80%;
      transform: translateY(-50%);
    }
  }
  &__form {
    grid-column: 11/21;
    position: relative;
    margin-top: 8em;
    .form {
      font-size: 1.375em;
      .no-valid {
        font-size: 0.8em !important;
      }
      .number {
        margin-bottom: 1em;
        display: block;
      }
      .next-btn {
        font-size: 1em;
        margin-top: 2em;
      }
    }
    .title {
      font-weight: 500;
      font-size: 2em;
      margin-bottom: 1.5em;
    }
  }
}
.modal {
  background: #fff;
  padding: 2em;
  font-size: 1.375em;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  .title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 1200px) {
  .grid {
    font-size: 12px;
    display: block;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: 80%;
    &__img {
      display: none;
      margin: 0 auto;
      max-width: 575px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      position: relative;
      width: 60%;
    }
  }
}

@media screen and (max-width: 576px) {
  .grid {
    font-size: 12px;
    display: block;
    // flex-direction: column-reverse;
    margin: 0 auto;
    width: 90%;

    &__img {
      display: none;
      // margin: 0 auto;
      margin-left: auto;
      margin-top: 0;
      margin-right: 0;
      max-width: 200px;
      .img {
        position: static;
        width: 100%;
      }
    }
    &__form {
      position: relative;
      width: 100%;
      .input-container {
        width: 100%;
      }
    }
  }
}
</style>
