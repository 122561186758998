<template>
  <div
    class="primary-input"
    :class="{
      focused: isFocus,
      disabled: disable,
      error: errText,
      success: success,
    }"
  >
    <div class="error-text" v-if="errText">
      {{ errText }}
    </div>
    <div class="wrap">
      <slot />
      <div class="icon" v-if="iconPath">
        <svg
          width="2.125rem"
          height="2.125rem"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            :d="iconPath"
            fill="#377DFF"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from "vue";

const isFocus = ref(false);

// const emit = defineEmits(["input"]);

defineProps({
  iconPath: String,
  errText: String,
  disable: Boolean,
  success: Boolean,
});
</script>
<style lang="scss">
.primary-input {
  width: 100%;

  &:hover {
    input {
      box-shadow: 0rem 0.5rem 1.75rem -0.38rem rgba(24, 39, 75, 0.12),
        0rem 1.13rem 5.5rem -0.25rem rgba(24, 39, 75, 0.14);
    }
  }

  .wrap {
    height: max-content;
    position: relative;
  }

  .icon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    top: 50%;
    right: 1.625rem;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
      path {
        fill: var(--disabled-color);
      }
    }
  }

  &.error {
    input {
      border-color: var(--error-color);
      color: var(--error-color);

      &:focus {
        color: var(--dark-blue);
      }
    }

    .icon {
      svg {
        path {
          fill: var(--error-color);
        }
      }
    }
  }

  &.disabled {
    .icon {
      svg {
        path {
          fill: var(--disabled-color);
        }
      }
    }
  }

  &.focused {
    .icon {
      svg {
        path {
          fill: var(--primary-blue);
        }
      }
    }
  }

  &.success {
    input {
      color: var(--success-color);
      border-color: var(--success-color);

      &:focus {
        border-color: var(--primary-blue);
        color: var(--dark-blue);
      }
    }
    .icon {
      svg {
        path {
          fill: var(--success-color);
        }
      }
    }
  }

  input {
    width: 100%;
    background: #f3f5f8;
    border-radius: 0.625rem;
    outline: none;
    font-weight: 500;
    font-size: 1em;
    line-height: 180%;
    padding: 1.25rem 1.625rem;
    color: var(--dark-blue);
    border: 0.0625rem solid transparent;
    transition: all 0.3s ease;

    &[type="date"]::-webkit-calendar-picker-indicator {
      //display: none;
      //-webkit-appearance: none;
      width: 1.875rem;
      height: 1.875rem;
      opacity: 0;
    }

    &:focus {
      border-color: var(--primary-blue);
    }

    &:focus ~ .icon svg path {
      fill: var(--primary-blue);
    }

    &:disabled {
      color: var(--disabled-color);
    }

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 1em;
      line-height: 180%;
      color: var(--disabled-color);
    }
  }

  .error-text {
    font-weight: 500;
    font-size: 1em;
    line-height: 180%;
    color: var(--error-color);
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .primary-input {
    .icon {
      width: 1.4rem;
      height: 1.4rem;
    }

    input {
      font-size: 0.9rem;
    }
  }
}
</style>
